import L from "leaflet"
import marker from "../images/pin.png";

const getMapping = (mapping) => {
    const mappings = {
        title: "title",
        description: 'description',
        desc: 'description',
        headline: 'headline',
        head: 'head',
        mission: 'mission',
        appointmentProcessNo: 'ProcessNumber',
        salary: 'Salary',
        contractType: 'ContractType',
        termDetails: 'TermDetails',
        jobDetails: 'JobFunction',
        classification: 'Classification',
        location: 'Location',
        numberOfVacancies: 'NumberOfVacancies',
        closeDate: 'CloseDate',
        appointmentProcessNoFr: 'ProcessNumber',
        salaryFR: 'SalaryFR',
        contractTypeFR: 'ContractTypeFR',
        termDetailsFR: 'TermDetailsFR',
        jobDetailsFR: 'JobFunctionFR',
        classificationFR: 'Classification',
        locationFR: 'LocationFR',
        numberOfVacanciesFR: 'NumberOfVacancies',
        closeDateFR: 'CloseDateFR',
        listDate: 'listDate'
    }
    return mappings[mapping];
}

function replaceAll(haystack, needle, replacement) {
    while (haystack.indexOf(needle) >= 0) {
        haystack = haystack.replace(needle, replacement);
    }
    return haystack
}

const removeAccents = (str) => {
    const accents = {
        a: 'àáâãäåæ', c: 'ç', e: 'èéêëæ', i: 'ìíîï', n: 'ñ', o: 'òóôõöø', s: 'ß', u: 'ùúûü', y: 'ÿ'
    }
    let newStr = "";
    for (let char in str) {
        let continueFlag = false;
        for (let accent in accents) {
            if (accents[accent].includes(str[char])) {
                newStr += accent;
                continueFlag = true;
                break;
            }
        }
        if (continueFlag) continue;
        newStr += str[char];
    }
    return newStr;
};
const distance = (lat1, lon1, lat2, lon2, unit) => {
    const R = 6371e3; // metres
    const φ1 = lat1 * Math.PI / 180; // φ, λ in radians
    const φ2 = lat2 * Math.PI / 180;
    const Δφ = (lat2 - lat1) * Math.PI / 180;
    const Δλ = (lon2 - lon1) * Math.PI / 180;
    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // in metres
    return d / 1000
}
const makeRandomKey = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

const positionUrl = (job, store, lang, head, path, locale) => {
    let parentPath = "/careers/";
    let location, state = "AZ";
    console.log(store)
    if (store.hasOwnProperty("location")) location = store['location'].toString();
    console.log("Pth: ", path)
    console.log("Pt: ", path.split("-")[0].replace("/processes/", ""))
    let url = parentPath + head + "-" + location + "-" + state + "-" + path.split("-")[0].replace("/processes/", "") + "-" + locale;
    url = url.toLowerCase()
    url = url.replaceAll("-/ ", "-")
    url = url.replaceAll("-/ ", "-")
    url = url.replaceAll("   ", "-")
    url = url.replaceAll("  ", "-")
    url = url.replaceAll(" ", "-")
    url = url.replaceAll(", ", "-")
    url = url.replaceAll(",", "")
    url = url.replaceAll("(", "")
    url = url.replaceAll("(", "")
    url = url.replaceAll(")", "")
    url = url.replaceAll("---", "-")
    url = url.replaceAll("--", "-")
    url = url.replaceAll("-/-", "-")
    url = url.replaceAll("-&-", "-")
    url = removeAccents(url)
    return url;
}
const createPopups = (feature = {}, layer) => {
    const {properties = {}} = feature
    const {address, jobs, symbol, storeIdentifier} = properties
    const popup = L.popup()

    //Track which markers are being actively displayed on the map

    let jobsHtml = ``;
    for (let job in jobs) {
        let url = new URL(jobs[job]['url'].toString())
        let locale = url.searchParams.get("locale");
        let careerText = process.env.LANG === "FR" ? "carrieres" : "careers";
        let posPath =  careerText + "/" + jobs[job].headline.toString() + "-" + storeIdentifier + "-az-" + jobs[job].path.split("-")[0].replace("/processes/", "") + "-" + locale;
        posPath = posPath.toLowerCase();
        posPath = replaceAll(posPath, "-/ ", "-");
        posPath = replaceAll(posPath, "-/ ", "-");
        posPath = replaceAll(posPath, "   ", "-");
        posPath = replaceAll(posPath, "  ", "-");
        posPath = replaceAll(posPath, " ", "-");
        posPath = replaceAll(posPath, ", ", "-");
        posPath = replaceAll(posPath, ",", "");
        posPath = replaceAll(posPath, "(", "");
        posPath = replaceAll(posPath, "(", "");
        posPath = replaceAll(posPath, ")", "");
        posPath = replaceAll(posPath, "---", "-");
        posPath = replaceAll(posPath, "--", "-");
        posPath = replaceAll(posPath, "-/-", "-");
        posPath = replaceAll(posPath, "-&-", "-");
        posPath = removeAccents(posPath);
        if(job > 2){continue;}
        jobsHtml += `
            <li class="${job % 2 === 0 ? "c-odd" : "c-even"}">
                <a class="r ac jsb" target="_blank" href="${posPath}">
                    <h4 class="c">${jobs[job].headline.toString()}</h4>
                    <p>${jobs[job].jobCategory ? jobs[job].jobCategory.toString() : "na"}</p>
                    <p>${jobs[job].shiftType ? jobs[job].shiftType.toString() : "All"}</p>
                </a>
            </li>`
    }
    const html = `
   <div class="popup-container">
        <h3 class="popup-header">
            <a target="_blank" href="/search/?term=${address}">${address.toString()}</a>
        </h3>
        <p class="openings">${jobs.length} - ${
      jobs.length === 1 ? "opening" : "openings"
    }</p>
        <ul class="job-list"> 
            ${jobsHtml}
        </ul>
        <p class="load-all-jobs"><a href="/search/?term=${address}">Load all careers at this location ≫</a></p>
    </div>
    `;
    popup.setContent(html)
    layer.bindPopup(popup,{offset:[0, 0]})
}
const getMonthNumber = (str, lang) => {
    let value = adjustSearchParams(str, lang);
    let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    if (lang === "FR") {
        months = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"]
    }
    let month = (months.indexOf(value) + 1).toString();
    return months.indexOf(value) + 1 < 10 ? `0${month}` : `${month}`;
}

const adjustSearchParams = (value, lang) => {
    if (!value) return;
    let param = value.toString();
    if (param.includes(" // ")) {
        if (lang === "FR") {
            let options = param.split(' // ');
            if (options.length === 2) {
                return options[1];
            } else if (options.length === 1) {
                return options[0];
            } else {
                return "N/A"
            }
        } else {
            let options = param.split(' // ');
            if (options.length >= 1) {
                return options[0];
            } else {
                return "N/A"
            }
        }
    } else if (param.includes(" / ")) {
        if (lang === "FR") {
            let options = param.split(' / ');
            if (options.length === 2) {
                return options[1];
            } else if (options.length === 1) {
                return options[0];
            } else {
                return "N/A"
            }
        } else {
            let options = param.split(' / ');
            if (options.length >= 1) {
                return options[0];
            } else {
                return "N/A"
            }
        }

    } else {
        if (param) {
            return param;
        } else {
            return "N/A"

        }
    }
}

const createClusters = (cluster) => {
    const childCount = cluster.getChildCount()
    let size = ""
    if (childCount < 10) {
        size = "small"
    } else if (childCount < 25) {
        size = "medium"
    } else {
        size = "large"
    }
    return L.divIcon({
         html:
           `<div class="c jc ac pos-rel">
            <img src="${marker}" style="max-width: 54px; height: auto; width: 100%;" /> 
            <span class="pos-abs count" style="">
            <b>${childCount}</b>
            </span>
            </div>`,
        className: `custom-marker-cluster custom-marker-cluster-${size}`,
        iconSize: new L.point(54, 54),
    })
}

export default distance;
export {distance, positionUrl, makeRandomKey, createPopups, createClusters, getMapping, adjustSearchParams, getMonthNumber};

